import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

const useStyles = makeStyles(theme => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    borderTop: "1px solid #e0e0e0",
  },
  whiteBg: {
    backgroundColor: theme.palette.background.paper,
  },

  gridContainer: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr",
    alignItems: "center",
    justifyItems: "center",
    gridGap: theme.spacing(2),

    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  headshot: {
    width: "200px",
    height: "200px",
    borderRadius: "100%",
    border: `5px solid ${theme.palette.primary.main}`,
  },
  sign: {
    fontFamily: "cursive",
    fontStyle: "italic",
  },
}))

const Intro = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query AvatarQuery {
      file(relativePath: { eq: "haruka-hero-mc.jpeg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <div className={classes.whiteBg}>
      <Container className={classes.cardGrid} maxWidth="lg">
        <div className={classes.gridContainer}>
          <div>
            <div>
              <Img
                fluid={data.file.childImageSharp.fluid}
                alt="Haruka Sinno profile"
                className={classes.headshot}
              />
            </div>
          </div>
          <div>
            <Typography
              component="h3"
              variant="h5"
              align="left"
              color="textPrimary"
              gutterBottom
            >
              “As a professionally qualified and experienced presenter in
              English and Japanese, I'm passionate about helping my clients
              overcome any language, intercultural or communication barriers.
              Enjoy the success of your event, and leave it to me to get your
              messages across smoothly.”
            </Typography>

            <Typography
              component="h5"
              variant="h6"
              align="left"
              color="textPrimary"
              className={classes.sign}
            >
              Haruka Shinno
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Intro
