import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"

import Layout from "../components/layout"
import Head from "../components/head"
import Services from "../components/services"
import Testimonials from "../components/testimonials"
import Intro from "../components/intro"

const useStyles = makeStyles(theme => ({
  heroContent: {
    position: "relative",
  },
  heroOverlay: {
    position: "absolute",
  },
  heroButtons: {
    marginTop: theme.spacing(5),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },

  girdContainer: {
    display: "grid",
    gridGap: theme.spacing(4),
    gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
    marginTop: theme.spacing(3),
  },
  cardGridContainer: {
    display: "grid",
    gridGap: theme.spacing(2),
    gridTemplateColumns: "100px 1fr",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "80px 1fr",
    },
  },
  videoWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    borderTop: "1px solid #e0e0e0",
  },
}))

const IndexPagePresenter = props => {
  const classes = useStyles()
  // const { companyName, description } = props.data.site.siteMetadata
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))
  const heroVideoHeight = matches ? "80vh" : "300px"

  return (
    <Layout>
      <Head title="Home" />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth={false} disableGutters>
            {/* <iframe
              title="Haruka sample voice movie in Japanese and English"
              width="100%"
              style={{ height: heroVideoHeight }} // Set the height using inline styles
              src="https://www.youtube.com/embed/_LHcE2Fttmg?autoplay=1&mute=1"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe> */}
            <iframe
              width="100%"
              style={{ height: heroVideoHeight }} // Set the height using inline styles
              src="https://www.youtube.com/embed/z6VVKnxkmLU?si=4lNS6cgtVPo1cw8v&amp;autoplay=1&mute=1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>{" "}
          </Container>
          {/* <div className={classes.heroOverlay}>
            <Typography
              component="h1"
              variant="h2"
              align="left"
              color="textPrimary"
              gutterBottom
            >
              {companyName}
            </Typography>
            <Typography
              variant="h5"
              align="left"
              color="textSecondary"
              paragraph
            >
              {description}
            </Typography>
          </div> */}
        </div>
        <Intro />
        <Services />
        <Testimonials />
        <Container className={classes.videoWrap} maxWidth="lg">
          <iframe
            title="Haruka sample voice movie in Japanese and English"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/_LHcE2Fttmg"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Container>
      </main>
    </Layout>
  )
}

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          companyName
          email
          description
        }
      }
      file(relativePath: { eq: "haruka-hero-mc.jpeg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return <IndexPagePresenter data={data} />
}

export default IndexPage
