import React from "react"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

import weddingSvg from "../../assets/wedding.svg"
import voiceSvg from "../../assets/voice_control.svg"
import conferenceSvg from "../../assets/conference.svg"

const WHAT_WE_DO = [
  {
    title: "MC",
    subtitle:
      "Wedding reception, grand opening events, gala dinner, award presentations, corporate events, entertaiment events, tourism events etc.",
    media: weddingSvg,
  },
  {
    title: "Presenter",
    subtitle: "Product launch, tradeshow presenter.",
    media: conferenceSvg,
  },
  {
    title: "Voice over / Narration",
    subtitle: "Voice-over for your Japanese and English audio visual needs.",
    media: voiceSvg,
  },
]

const useStyles = makeStyles(theme => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    borderTop: "1px solid #e0e0e0",
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
    height: "100%",
  },
  girdContainer: {
    display: "grid",
    gridGap: theme.spacing(4),
    gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
    marginTop: theme.spacing(3),
  },
  cardGridContainer: {
    display: "grid",
    gridGap: theme.spacing(5),
    gridTemplateRows: "240px 1fr",
    justifyContent: "center",
  },
  media: {
    height: "80%",
    width: "80%",
    objectFit: "contain",
  },
  mediaWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

const Services = () => {
  const classes = useStyles()

  return (
    <Container className={classes.cardGrid} maxWidth="lg">
      <Typography
        component="h2"
        variant="h4"
        align="center"
        color="textPrimary"
        gutterBottom
      >
        Services
      </Typography>
      <div className={classes.girdContainer}>
        {WHAT_WE_DO.map(item => (
          <Card className={classes.item} key={item.title} elevation={0}>
            <CardContent className={classes.cardContent}>
              <div className={classes.cardGridContainer}>
                <div className={classes.mediaWrap}>
                  <img
                    src={item.media}
                    alt={item.title}
                    className={classes.media}
                  />
                </div>

                <div>
                  <Typography gutterBottom variant="h4" component="h3">
                    {item.title}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body1"
                    component="p"
                    color="textSecondary"
                  >
                    {item.subtitle}
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </Container>
  )
}

export default Services
