import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import TestmonialsPresenter from "./testimonials"

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      jcsSrc: file(relativePath: { eq: "jcs-logo.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      sazaeSrc: file(relativePath: { eq: "sazae-logo.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      brainSrc: file(relativePath: { eq: "brain-power-logo.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const TESTIMONIALS = [
    {
      name: "Peter Gibson",
      title: "Executive Advisor Tokyo 2020 Australian Olympic Committee ",
      comment:
        "It was a pleasure to co-MC with Haruka. Haruka is an incredibly talented bilingual professional. Very polished in either English and Japanese. I would have no hesitation in recommending her for any type of MC work.",
    },
    {
      name: "Yukiko Hirano",
      title: "JCS Rainbow Project Presiden",
      comment:
        "準備段階からしっかりと計画して細かく司会の内容を詰めて下さったので、本当に素晴らしい司会になりました。又話し方や声のトーンもわかりやすく聞きやすく申し分のない司会でした。映画のナレーションも声が透き通っていて、トーンもシーンに合っていたので、映画を一層に引き立てていました。他にも司会やナレーションの要望があればはるかさんを是非推薦致します。",
      media: data.jcsSrc,
    },
    {
      name: "Ayumi Mizoshiri",
      title: "Director Pty Ltd ",
      comment:
        "とてもプロフェッショナルなサービスでイベントが盛り上がることができました。また機会がありましたらぜひお願いしたいと思います。",
      media: data.sazaeSrc,
    },
    {
      name: "Löeby Lovato",
      title: "Director/Media Manager/PM",
      comment:
        "Can't thank Haruka enough for bringing my script to life with her right modulation, voice intensity, adaptation of rhythm of speech and more importantly her 'easy going' attitude to work with. I look forward to working with Haruka soon, and I'd recommend her to anyone in need of a bilingual Japanese-English MC and or (VO)Voice over calls. Arigato!",
      media: data.brainSrc,
    },
    {
      name: "Hideki Konno",
      title: "Avain Australia",
      comment:
        "今回はスタッフ管理システム及び打刻システムの説明動画でのナレーション依頼をさせてもらいました。とても綺麗な英語と日本語で大変感動しました。クライアントからも、とてもクリアでわかりやすい説明と聞きました。次もぜひお願いしたいと思っています。 ありがとうございました😊",
    },
    {
      name: "Maki Watabe",
      title: "Account Planning Manager, doq Pty Ltd",
      comment:
        "今回司会者を手配するのが初めてだったので、Harukaさんに台本の準備や豪州でのイベントについての助言をいただきとても助かりました。また、クライアント側も大変満足されていましたので、感謝しかないです！完璧なご対応でした。",
    },
  ]

  return <TestmonialsPresenter testimonials={TESTIMONIALS} />
}

export default Testimonials
