import React from "react"
import Img from "gatsby-image"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

const useStyles = makeStyles(theme => ({
  whiteBg: {
    backgroundColor: theme.palette.background.paper,
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    borderTop: "1px solid #e0e0e0",
  },
  card: {
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },

  girdContainer: {
    display: "grid",
    gridGap: theme.spacing(4),
    gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
    marginTop: theme.spacing(3),
    alignItems: "flex-start",
  },
  cardGridContainer: {
    display: "grid",
    gridGap: theme.spacing(7),
    justifyContent: "center",
  },
  media: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },

  innerGrid: {
    display: "grid",
    gridGap: theme.spacing(3),
    gridTemplateColumns: "120px 1fr",
    justifyContent: "center",
  },
  noImg: {
    display: "flex",
  },
}))

const Testimonials = ({ testimonials }) => {
  const classes = useStyles()

  return (
    <div className={classes.whiteBg}>
      <Container className={classes.cardGrid} maxWidth="lg">
        <Typography
          component="h2"
          variant="h4"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Testimonials
        </Typography>
        <div className={classes.girdContainer}>
          {testimonials.map(item => (
            <Card
              className={classes.item}
              key={item.name}
              elevation={0}
              variant="outlined"
            >
              <CardContent>
                <div className={classes.cardGridContainer}>
                  <Typography
                    gutterBottom
                    variant="body1"
                    component="p"
                    color="textSecondary"
                  >
                    {item.comment}
                  </Typography>

                  <div
                    className={item.media ? classes.innerGrid : classes.noImg}
                  >
                    {item.media && (
                      <div>
                        <Img
                          fluid={item.media.childImageSharp.fluid}
                          alt={item.name}
                        />
                      </div>
                    )}
                    <div>
                      <Typography gutterBottom variant="h5" component="h3">
                        {item.name}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="body1"
                        component="p"
                        color="textSecondary"
                      >
                        {item.title}
                      </Typography>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default Testimonials
